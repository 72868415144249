//const $ = require('//ajax.googleapis.com/ajax/libs/jquery/1.11.0/jquery.min.js');

function initLRSPage() {
  $(document).trigger("enhance.tablesaw");

  $('button.hide-quote').click(function (event) {
    $(this).parents('.container').slideUp();
  });

  $('.view-details').click(function (e) {
    e.preventDefault();
    $('.product-detail').addClass('visible');
  });

  $('.date-error .close').on('click', function () {
    $('.date-error').removeClass('date-selected');
  });

  $('.close-dialog').on('click', function () {
    $(this).parents('.dialog-container').addClass('hide-dialog');
  });

  $('.revealFilters').on('click', function () {
    $('#sidebar').toggleClass('active');
  });

  $('.create-alert').on('click', function (e) {
    e.preventDefault();
    $(this).children('.button-options').toggleClass('active');
  });

  $('.edit-profile-btn .btn').on('click', function (e) {
    e.preventDefault();
    $('.edit-profile-btn').toggleClass('editing');
    $('.edit-section, .review-section').toggleClass('editing');
  });

  $('.filters-display-toggle').on('click', function () {
    $('.filters-mobile').toggleClass('open');
  });

  $('.modal-box .fa.fa-close').on('click', function () {
    $('.modalbox-container').addClass('hidden');
  });

  $('.get-started.btn').on('click', function () {
    $('.modalbox-container').removeClass('hidden');
  });

  $('.datepicker').datepicker();
  $("input.phone").mask("(999) 999-9999");

  $('.scroll-to-top').on('click', function () {
    if (!($('body')[0].classList.contains('authenticated'))) {
      window.scrollTo(0, 0);
    }
  });

  $('.share-link').each(function () {
    var $this = $(this);
    var shareURL = window.location.href;
    var title = $('.job-details header dd').text().trim();

    if ($this.hasClass('icon-facebook')) {
      shareURL = "https://www.facebook.com/sharer/sharer.php?u=" + shareURL;
    } else if ($this.hasClass('icon-twitter')) {
      shareURL = "https://twitter.com/home?status=" + shareURL;
    } else if ($this.hasClass('icon-linkedin')) {
      shareURL = "https://www.linkedin.com/shareArticle?mini=true&url=" + shareURL + "%23&title=" + title + "&summary=&source=";
    } else if ($this.hasClass('icon-google-plus')) {
      shareURL = "https://plus.google.com/share?url=" + shareURL;
    }

    $this.on('click', function () {
      window.open(shareURL, '_blank');
    });
  });


  // $(window).scroll(function () {
  //   var scroll = $(window).scrollTop();


  //   if (scroll >= 50) {
  //     $("body").addClass("scrolled");
  //   } else if (scroll <= 200 && scroll >= 100) {
  //     $("body").removeClass("scrolled");
  //   }
  // });

  // Accordions
  $('.accordions h3').each(function () {
    var $this = $(this);

    // create unique id for a11y relationship
    var id = 'accordion-' + $this.index();

    // wrap the content and make it focusable
    $this.nextUntil('h3').wrapAll('<div id="' + id + '" aria-hidden="true">');
    var panel = $this.next();

    // Add the button inside the <h2> so both the heading and button semanics are read
    $this.wrapInner('<button aria-expanded="false" aria-controls="' + id + '">');
    var button = $this.children('button');

    // Toggle the state properties
    button.on('click', function (e) {
      e.preventDefault();
      var state = $(this).attr('aria-expanded') === 'false' ? true : false;
      $(this).attr('aria-expanded', state);
      $(this).parent('h3').toggleClass('is-active');
      panel.attr('aria-hidden', !state);
    });

    if ($this.hasClass('open')) {
      button.attr('aria-expanded', 'true');
      $this.toggleClass('is-active');
      panel.attr('aria-hidden', 'false');
    }
  });

  // Tabs
  // The class for the container div
  var tabContainer = '.tabs';

  // The setup
  $(tabContainer + ' ul.tablist').attr('role', 'tablist').empty();
  // $(tabContainer +' [role="tablist"]').append('role','presentation');
  $(tabContainer + ' .tab-content').each(function () {
    var tabHeading = $(this).find(">:first-child").text();
    var tabHeadingID = $(this).find(">:first-child").attr('id');
    $(tabContainer + ' ul.tablist').append('<li><a href="#' + tabHeadingID + '">' + tabHeading + '</a></li>');
  });
  $(tabContainer + ' [role="tablist"] li').attr('role', 'presentation');
  $('[role="tablist"] a').attr({
    'role': 'tab',
    'tabindex': '-1'
  });

  // Make each aria-controls correspond id of targeted section (re href)
  $('[role="tablist"] a').each(function () {
    $(this).attr(
      'aria-controls', $(this).attr('href').substring(1)
    );
  });

  // Make the first tab selected by default and allow it focus
  $('[role="tablist"] li:first-child').addClass('active');
  $('[role="tablist"] li:first-child a').attr({
    'aria-selected': 'true',
    'tabindex': '0'
  });

  // Make each section focusable and give it the tabpanel role
  $(tabContainer + ' section').attr({
    'role': 'tabpanel'
  });

  // Make first child of each panel focusable programmatically
  $(tabContainer + ' section > *:first-child').attr({
    'tabindex': '0'
  });

  // Make all but the first section hidden (ARIA state and display CSS)
  $('[role="tabpanel"]:not(:first-of-type)').attr({
    'aria-hidden': 'true'
  });

  // Change focus between tabs with arrow keys
  $('[role="tab"]').on('keydown', function (e) {

    // define current, previous and next (possible) tabs
    var tabCurrent = $(this);
    var tabPrev = $(this).parents('li').prev().children('[role="tab"]');
    var tabNext = $(this).parents('li').next().children('[role="tab"]');
    var tabTarget;

    // find the direction (prev or next)
    switch (e.keyCode) {
      case 37:
        tabTarget = tabPrev;
        break;
      case 39:
        tabTarget = tabNext;
        break;
      default:
        tabTarget = false;
        break;
    }

    if (tabTarget.length) {
      $('.tab-list li').removeClass('active');
      tabCurrent.parents('li').addClass('active');
      tabCurrent.attr({
        'tabindex': '-1',
        'aria-selected': null
      });
      tabTarget.attr({
        'tabindex': '0',
        'aria-selected': true
      }).focus();
    }

    // Hide panels
    $(tabContainer + ' [role="tabpanel"]')
      .attr('aria-hidden', 'true');

    // Show panel which corresponds to target
    $('#' + $(document.activeElement).attr('href').substring(1))
      .attr('aria-hidden', null);
  });

  // Handle click on tab to show + focus tabpanel
  $('[role="tab"]').on('click', function (e) {
    e.preventDefault();

    // remove focusability [sic] and aria-selected
    $('.tab-list li').removeClass('active');
    $('[role="tab"]').attr({
      'tabindex': '-1',
      'aria-selected': null
    });

    // replace above on clicked tab
    $(this).parents('li').addClass('active');
    $(this).attr({
      'aria-selected': true,
      'tabindex': '0'
    });

    // Hide panels
    $(tabContainer + ' [role="tabpanel"]').attr('aria-hidden', 'true');

    // show corresponding panel
    $('#' + $(this).attr('href').substring(1))
      .attr('aria-hidden', null);
  });

  $('.btn-clear-selected').on('click', function (e) {
    e.preventDefault();
    var controlID = $(this).data('target');
    $('ul[id*=' + controlID + '] .new-checkbox').each(function (el) {
      $(this).removeClass('checked');
      $(this).children("input").attr('checked', false);
    });
  });

  $('.btn-select-all').on('click', function (e) {
    e.preventDefault();
    var controlID = $(this).data('target');
    $('ul[id*=' + controlID + '] .new-checkbox').each(function (el) {
      $(this).addClass('checked');
      $(this).children("input").attr('checked', true);
    });
  });

  function setActiveTab(tabID) {
    // remove focusability [sic] and aria-selected
    $('.tab-list li').removeClass('active');
    $('[role="tab"]').attr({
      'tabindex': '-1',
      'aria-selected': null
    });

    // replace above on clicked tab
    $(tabID).parents('li').addClass('active');
    $(tabID).attr({
      'aria-selected': true,
      'tabindex': '0'
    });

    // Hide panels
    $(tabContainer + ' [role="tabpanel"]').attr('aria-hidden', 'true');

    // show corresponding panel
    $('#' + $(tabID).attr('href').substring(1))
      .attr('aria-hidden', null);
  }

  $('.custom-dropdown-menu').on('change', function () {
    window.location.href = $(this).val();
  });

  $('.print-button').on('click', function () {
    window.print();
  });

  $('.menu-toggle').on('click', function () {
    $('ul.nav.navbar-nav').toggleClass('open');
  });

  //Add target blank to any link beginning with http
  $('a[href^="http://"], a[href^="https://"]').not('a[href*=iasajobbank-2016], a[href*=illinoiseducationjobbank]').attr('target', '_blank');

  //Listen for content to be added to Alert Container and prevent the saving dialog if it is.
  $('.alertContainer').on("DOMNodeInserted, DOMNodeRemoved", function () {
    $('body').removeClass('saving');
  });
}

jQuery(document).ready(function () {
  initLRSPage();
});


var prm = Sys.WebForms.PageRequestManager.getInstance();
prm.add_endRequest(function () {
  initLRSPage();
  $("body").removeClass("saving");
});
